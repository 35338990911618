/* HTMX loader */
import htmx from "htmx.org";

/* Expose htmx global */
window.htmx = window.htmx || htmx;

/* Add any HTMX configuration here */
window.htmx.config.allowEval = false;
window.htmx.config.includeIndicatorStyles = false;
window.htmx.config.scrollBehavior = "smooth";
window.htmx.config.inlineScriptNonce = document.currentScript.nonce || "";

/* Extensions */
/* Alpine Morph copied from htmx.org/dist/ext/alpine-morph.js */
window.htmx.defineExtension("alpine-morph", {
  isInlineSwap: function (swapStyle) {
    return swapStyle === "morph";
  },
  handleSwap: function (swapStyle, target, fragment) {
    if (swapStyle === "morph") {
      if (fragment.nodeType === Node.DOCUMENT_FRAGMENT_NODE) {
        window.Alpine.morph(target, fragment.firstElementChild);
        return [target];
      } else {
        window.Alpine.morph(target, fragment.outerHTML);
        return [target];
      }
    }
  },
});

/*
 * Workaround for Alpine templating being broken when htmx restores from
 * history. Some times in loops &c items get re-created multiple times after a
 * history event (see e.g. the recently viewed product list).
 *
 * Put x-from-template on elements that cause an issue and this will remove
 * them so they can be recreated properly.
 *
 * Details at:
 * https://github.com/alpinejs/alpine/discussions/2924#discussioncomment-3877977
 *
 */
if (!window.htmxAlpineFix) {
  document.addEventListener("htmx:historyRestore", () => {
    document.querySelectorAll("[x-from-template]").forEach((e) => e.remove());
  });
  // We only want to set up this listener once so everything stays sane if this
  // file gets reloaded (e.g. by a `hx-boost`ed anchor)
  window.htmxAlpineFix = true;
}

/*
 * When we use hx-boost and the htmx history it breaks our usage of classes on
 * the body element (which we use extensively) because it only swaps the
 * innerHTML. This keeps track of the classes for each URL and restores them
 * as appropriate.
 */
const bodyClassListKey = "bodyClassList";

let bodyClassList;
try {
  bodyClassList = new Map(
    JSON.parse(window.localStorage.getItem(bodyClassListKey)),
  );
} catch (e) {
  bodyClassList = new Map();
}

function restoreBodyClassList(pathname) {
  const classlist = bodyClassList.get(pathname);
  if (classlist !== undefined) {
    document.body.classList.value = classlist;
  }
}

function saveBodyClassList(pathname, classlist) {
  const bodyClassExclude =
    /jsm-in-footer|jsm-past-header|htmx-(settling|request|swapping|settling|added)/g;
  if (pathname === undefined) {
    pathname = document.location.pathname;
  }
  if (classlist === undefined) {
    classlist = document.body.classList.value;
  }
  if (pathname && classlist !== undefined) {
    bodyClassList.set(pathname, classlist.replace(bodyClassExclude, ""));
    window.localStorage.setItem(
      bodyClassListKey,
      JSON.stringify([...bodyClassList]),
    );
  }
}

if (!window.htmxBodyClassFix) {
  document.addEventListener("htmx:beforeHistorySave", () => {
    saveBodyClassList();
  });

  document.addEventListener("htmx:beforeSwap", (evt) => {
    if (evt.detail.boosted && evt.detail.target === document.body) {
      saveBodyClassList();
    }
  });

  document.addEventListener("htmx:afterSwap", (evt) => {
    if (evt.detail.boosted && evt.detail.target === document.body) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(
        evt.detail.xhr.responseText,
        "text/html",
      );
      if (doc.body && doc.body.classList) {
        document.body.classList.value = doc.body.classList.value;
        saveBodyClassList(
          new URL(evt.detail.requestConfig.path, document.location).pathname,
          doc.body.classList.value,
        );
      }
    }
  });

  document.addEventListener("htmx:historyRestore", (evt) => {
    let path = evt.detail.path;
    try {
      path = new URL(path, document.location).pathname;
    } catch (e) {
      path = path.split("?", 1)[0];
    }
    restoreBodyClassList(path);
  });

  // We only want to set up these listeners once so everything stays sane if
  // this file gets reloaded (e.g. by a `hx-boost`ed anchor)
  window.htmxBodyClassFix = true;
}
